@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import './components/AddNews/index.css'; */
@import './components/Admin/AddNews/styles/index.css';
@import '../node_modules/react-toastify/dist/ReactToastify.css';
@import './components/User/Home/styles/index.css';

html {
    scroll-behavior: smooth;
}