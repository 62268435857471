.clear {
    clear: both;
  }
  
  .checkBox {
    display: block;
    cursor: pointer;
    width: 18px;
    height: 18px;
    border: 3px solid rgb(0, 0, 0);
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 0px 2px #fff;
  }
  
  .checkBox div {
    width: 30px;
    height: 30px;
    background-color: #2F80ED;
    top: -52px;
    left: -52px;
    position: absolute;
    transform: rotateZ(45deg);
    z-index: 100;
  }
  
  .checkBox input[type=checkbox]:checked + div {
    left: -10px;
    top: -10px;
  }
  
  .checkBox input[type=checkbox] {
    position: absolute;
    left: 50px;
    visibility: hidden;
  }
  
  .transition {
    transition: 300ms ease;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}